module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Interstellar | Web Agency Next Generation","short_name":"Interstellar","start_url":"/","icon":"static/favicon.png","background_color":"#177f5f","theme_color":"#177f5f","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"571ba9254e52be2e2c3712c84a37dada"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["GTM-KBDRMJC"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"path":"/opt/build/repo/locales","languages":["fr","en"],"defaultLanguage":"fr","siteUrl":"https://interstellar.run","i18nextOptions":{"ns":["translation","custom"],"preload":["fr","en"],"load":"all","interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false}},
    }]
